import { FunctionComponent } from "react";

interface IntroProps {}

const Intro: FunctionComponent<IntroProps> = () => {
  return (
    <>
      <div className="bg-white p-4 pt-14">
        <p className="bg-indigo-100 rounded-full text-gray-600 inline-block px-4 py-1 font-semibold">
          A buy list app made for swedish markets.
        </p>
        <h2 className="text-4xl mt-8 leading-relaxed">
          Make a buy list, <br />
          <span className="relative inline-block">
            <span className="rotate-3 bg-indigo-500 absolute top-0 left-0 right-0 bottom-0"></span>
            <span className="relative z-10 text-white px-2">Easily</span>
          </span>
          And
          <span className="relative inline-block">
            <span className="rotate-3 bg-indigo-500 absolute top-0 left-0 right-0 bottom-0"></span>
            <span className="relative z-10 text-white px-2">Share</span>
          </span>
          with friends
        </h2>
        <p className="mt-4 lg:px-32 text-gray-600">
          For weekly shopping, preparing for parties, picnics,...
        </p>
        <div className="mt-8 hidden">
          <div className="bg-video relative m-auto bg-gray-200">
            <iframe
              src="https://player.vimeo.com/video/690673068?h=94400078bd"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="SplittaExpens"
            ></iframe>
          </div>
          <a
            href="/"
            className="rounded-full border px-8 py-2 mt-4 inline-block text-2xl"
          >
            Explore
          </a>
        </div>
      </div>
      <div className="px-4 mt-16">
        <h2 className="text-4xl font-semibold">Explore the features</h2>
        <div className="grid text-white mt-16 flex-wrap text-left gap-4 md:grid-cols-3 bg-gray-100">
          <div className="p-8 rounded-lg shadow-md bg-gradient-to-r from-violet-500 to-fuchsia-500">
            <h4 className="text-xl font-semibold">Your lists in cloud</h4>
            <ul className="mt-4 ml-8 list-disc font-medium">
              <li>
                Your data is saved in{" "}
                <span className="border-b-2">the cloud</span> so you can access
                the same lists every where.
              </li>
            </ul>
          </div>
          <div className="p-8 rounded-lg mt-4 shadow-md md:mt-0 bg-gradient-to-r from-orange-500 to-amber-500">
            <h4 className="text-xl font-semibold">
              Suggestions from swedish super markets
            </h4>
            <ul className="mt-4 ml-8 list-disc font-medium">
              <li>
                You get <span className="border-b-2">suggestions</span> when
                creating lists and these items are from{" "}
                <span className="border-b-2">swedish markets</span>.
              </li>
            </ul>
          </div>
          <div className="p-8 rounded-lg mt-4 shadow-md md:mt-0 bg-gradient-to-r from-indigo-500 to-sky-500">
            <h4 className="text-xl font-semibold">Share with friends</h4>
            <ul className="mt-4 ml-8 list-disc font-medium">
              <li>
                Easy to prepare together by share{" "}
                <span className="border-b-2">the same buy list</span>.
              </li>
            </ul>
          </div>
        </div>
        <div className="h-16"></div>
      </div>
    </>
  );
};

export default Intro;
