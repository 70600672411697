import { FunctionComponent } from "react";
import Intro from "./components/LandingPage/Intro";
import Concepts from "./components/LandingPage/Concepts";
import Footer from "./components/LandingPage/Footer";
import GuideLines from "./components/LandingPage/GuideLines";
import Layout from "./components/Layout";

interface LandingPageProps {}

const menu = [
  { label: "Getting started", url: "/" },
  { label: "App", url: "/" },
  { label: "Privacy", url: "/privacy" },
];

const LandingPage: FunctionComponent<LandingPageProps> = () => {
  return (
    <>
      <h1 className="text-2xl relative -bottom-8 inline-block p-4 text-white font-semibold rounded-full bg-gradient-to-r from-sky-500 to-indigo-500 -mt-8">
        Inköp list
      </h1>
      <Intro />
      <Concepts />
      <GuideLines />
      <div className="mt-8 lg:mt-16">
        <h2 className="text-4xl font-bold px-4">Build for daily use</h2>
        <p className="px-4">
          The app was initially developed for the needs of large buying list
          between a group of friends for parties, picnics.
        </p>
      </div>
    </>
  );
};

export default LandingPage;
