import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import LandingPage from "./LandingPage";
import Privacy from "./Privacy";
import ChecInAppPrivacy from "./checkInApp/Privacy";
import PokerInAppPrivacy from "./pokerInApp/Privacy";
import CardsOrganizerPrivacy from "./cardsOrganizer/Privacy";
import StreaksChallengePrivacy from "./streaksChallenge/Privacy";

interface RoutesProps {}

const AppRoutes: FunctionComponent<RoutesProps> = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/checkinapp/privacy" element={<ChecInAppPrivacy />} />
        <Route path="/pokerinapp/privacy" element={<PokerInAppPrivacy />} />
        <Route path="/cardsorganizer/privacy" element={<CardsOrganizerPrivacy />} />
        <Route path="/streakschallenge/privacy" element={<StreaksChallengePrivacy />} />
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
