import { FunctionComponent } from "react";

interface ConceptsProps {

}

const concepts = [
  {
    name: 'Suggestions',
    explanation: 'Suggestions are items that can be found in swedish super markets.'
  },
  {
    name: 'Templates',
    explanation: 'Templates can be used to add quickly items. Templates can be a recipe, a list that you usually buy.'
  },
  {
    name: 'Shared lists',
    explanation: 'A shared list is a list that you and your friends can collaborate together. A list can be shared emails'
  }
]

const Concepts: FunctionComponent<ConceptsProps> = () => {
  return (
    <div className="mt-16">      
      <div className="bg-black flex pt-16">
        <h3 className="relative text-4xl text-white pl-4 font-semibold">Concepts</h3>
        <div className="grid pb-16 px-4 flex-wrap gap-4 md:grid-cols-3 md:gap-8 md:px-8 md:gap-y-8 -mt-12 relative -top-16">
          {
            concepts.map((concept, index) => (
              <div key={index} className="basis-full py-6 px-4 rounded-lg bg-white shadow-xl">
                <h2 className="text-xl font-semibold">{concept.name}</h2>
                <p className="text-gray-600 text-center">
                  {concept.explanation}
                </p>
              </div>
            ))
          }

        </div>
      </div>
    </div>);
}

export default Concepts;