import { FunctionComponent } from "react";

interface PrivacyProps {}

const Privacy: FunctionComponent<PrivacyProps> = () => {
  return (
    <div className="px-8">
      <h1 className="text-2xl font-bold">PRIVACY NOTICE</h1>
      <span className="subtitle">
        Last updated <span className="">Apr 03, 2023</span>
      </span>
      <div className="text-left">
        <p className="text-left mt-4">
          <span data-custom-class="body_text">
            This privacy notice for <span className="question">Duc Mai</span>(
            <span className="block-component"></span>"
            <span className="block-component"></span>
            <strong>Company</strong>
            <span className="statement-end-if-in-editor"></span>," "
            <strong>we</strong>," "<strong>us</strong>," or "
            <strong>our</strong>"
            <span className="statement-end-if-in-editor"></span>
          </span>
          ), describes how and why we might collect, store, use, and/or share (
          <span className="block-component"></span>"<strong>process</strong>"
          <span className="statement-end-if-in-editor"></span>) your information
          when you use our services (<span className="block-component"></span>"
          <strong>Services</strong>"
          <span className="statement-end-if-in-editor"></span>), such as when
          you:
        </p>
        <ul className="pl-8 list-disc my-4">
          <li>
            Download and use our mobile application (Cards Organizer), or any other
            application of ours that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
        <p>
          <strong>Questions or concerns?</strong> Reading this privacy notice
          will help you understand your privacy rights and choices. If you do
          not agree with our policies and practices, please do not use our
          Services. If you still have any questions or concerns, please contact
          us at{" "}
          <a
            className="underline text-blue-600"
            href="mailto:mrducmv88@gmail.com"
          >
            {" "}
            mrducmv88@gmail.com
          </a>
          .
        </p>
        <h2 className="font-bold mt-4">SUMMARY OF KEY POINTS</h2>
        <p className="italic mt-4">
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also click{" "}
          <a className="underline" href="#content">
            here
          </a>{" "}
          to go directly to our table of contents.
        </p>
        <p className="mt-4">
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with Duc Mai and the
          Services, the choices you make, and the products and features you use.
          <a className="underline" href="#collect-information">
            {" "}
            Click here to learn more.
          </a>
        </p>
        <p className="mt-4">
          <strong>Do we process any sensitive personal information?</strong> We
          do not process sensitive personal information.
        </p>
        <p className="mt-4">
          <strong>Do we receive any information from third parties?</strong> We
          do not receive any information from third parties.
        </p>
        <p className="mt-4">
          <strong>How do we process your information?</strong> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so.{" "}
          <a className="underline" href="#process-information">
            Click here to learn more.
          </a>
        </p>
        <p className="mt-4">
          <strong>
            In what situations and with which parties do we share personal
            information?
          </strong>{" "}
          We may share information in specific situations and with specific
          third parties.{" "}
          <a className="underline" href="#share-personal-information">
            Click here to learn more.
          </a>
        </p>
        <p className="mt-4">
          <strong>How do we keep your information safe?</strong> We have
          organizational and technical processes and procedures in place to
          protect your personal information. However, no electronic transmission
          over the internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information.{" "}
          <a className="underline" href="#how-keep-safe">
            Click here to learn more.
          </a>
        </p>
        <p className="mt-4">
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information.{" "}
          <a className="underline" href="#privacy-rights">
            Click here to learn more.
          </a>
        </p>
        <p className="mt-4">
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by filling out our data subject request form
          available here, or by contacting us. We will consider and act upon any
          request in accordance with applicable data protection laws.
        </p>
        <p className="mt-4">
          <strong>
            Want to learn more about what Duc Mai does with any information we
            collect?
          </strong>{" "}
          <a className="underline" href="#collect-information">
            Click here to review the notice in full.
          </a>
        </p>
        <h2 id="content" className="mt-4 font-bold">
          TABLE OF CONTENTS
        </h2>
        <ol className="list-decimal pl-10">
          <li>
            <a className="text-blue-600 underline" href="#collect-information">
              WHAT INFORMATION DO WE COLLECT?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#process-information">
              HOW DO WE PROCESS YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#legal-bases">
              WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </a>
          </li>
          <li>
            <a
              className="text-blue-600 underline"
              href="#share-personal-information"
            >
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#handle-social-logins">
              HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </a>
          </li>
          <li>
            <a
              className="text-blue-600 underline"
              href="#how-long-keep-information"
            >
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#how-keep-safe">
              HOW DO WE KEEP YOUR INFORMATION SAFE?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#privacy-rights">
              WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#do-not-track">
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#california-residents">
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#update-notice">
              DO WE MAKE UPDATES TO THIS NOTICE?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#contact-notice">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
          </li>
          <li>
            <a className="text-blue-600 underline" href="#review-data">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
          </li>
        </ol>
        <h2 className="font-bold mt-4" id="collect-information">
          1. WHAT INFORMATION DO WE COLLECT?
        </h2>
        <div className="mt-2">
          <strong>Personal information you disclose to us </strong>
          <p>
            <em>
              In Short: We collect personal information that you provide to us.
            </em>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p className="mt-2">
            <strong>Personal Information Provided by You.</strong>
            The personal information that we collect depends on the context of
            your interactions with us and the Services, the choices you make,
            and the products and features you use. The personal information we
            collect may include the following:
            <br />
            <ul className="list-disc pl-8">
              <li> email addresses</li>
            </ul>
          </p>
          <p className="mt-2">
            <strong>Sensitive Information. </strong>We do not process sensitive
            information.
          </p>
          <p className="mt-2">
            <strong>Social Media Login Data.</strong> We may provide you with
            the option to register with us using your existing social media
            account details, like your Facebook, Twitter, or other social media
            account. If you choose to register in this way, we will collect the
            information described in the section called
            <a className="underline text-blue-600" href="#handle-social-logins">
              {" "}
              "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
            </a>{" "}
            below.
          </p>
          <p className="mt-2">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <p className="mt-2">
            <strong>Information automatically collected</strong>
            <br />
            <em>
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </em>{" "}
            <br />
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
            <br />
            The information we collect includes:
            <br />
            <ul>
              <li>
                <em>Log and Usage Data.</em> Log and usage data is
                service-related, diagnostic, usage, and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type, and settings and information
                about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches, and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called "crash dumps"), and hardware
                settings).
              </li>
            </ul>
          </p>
        </div>
        <h2 id="process-information" className="mt-4 font-bold">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </h2>
        <div className="mt-2">
          <em>
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </em>
          <p>
            <strong>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </strong>
            <ul className="list-disc pl-8 font-bold">
              <li>
                To facilitate account creation and authentication and otherwise
                manage user accounts. We may process your information so you can
                create and log in to your account, as well as keep your account
                in working order.
              </li>
              <li>
                To save or protect an individual's vital interest. We may
                process your information when necessary to save or protect an
                individual’s vital interest, such as to prevent harm.
              </li>
            </ul>
          </p>
        </div>
        <h2 id="legal-bases" className="mt-4 font-bold">
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </h2>
        <div className="mt-2">
          <em>
            In Short: We only process your personal information when we believe
            it is necessary and we have a valid legal reason (i.e., legal basis)
            to do so under applicable law, like with your consent, to comply
            with laws, to provide you with services to enter into or fulfill our
            contractual obligations, to protect your rights, or to fulfill our
            legitimate business interests.
          </em>
          <p className="mt-2">
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
            <ul className="pl-8 list-disc">
              <li>
                <strong>Consent. </strong>We may process your information if you
                have given us permission (i.e., consent) to use your personal
                information for a specific purpose. You can withdraw your
                consent at any time. Click here to learn more.
              </li>
              <li>
                <strong>Legal Obligations.</strong> We may process your
                information where we believe it is necessary for compliance with
                our legal obligations, such as to cooperate with a law
                enforcement body or regulatory agency, exercise or defend our
                legal rights, or disclose your information as evidence in
                litigation in which we are involved.
              </li>
              <li>
                <strong>Vital Interests.</strong> We may process your
                information where we believe it is necessary to protect your
                vital interests or the vital interests of a third party, such as
                situations involving potential threats to the safety of any
                person.
              </li>
            </ul>
          </p>
        </div>
        <h2 id="share-personal-information" className="mt-4 font-bold">
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h2>
        <div className="mt-2">
          <em>
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
          </em>{" "}
          <p className="mt-2">
            We may need to share your personal information in the following
            situations:
            <ul className="list-disc pl-8">
              <li>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
            </ul>
          </p>
        </div>{" "}
        <h2 id="handle-social-logins" className="mt-4 font-bold">
          5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </h2>
        <div className="mt-2">
          <em>
            In Short: If you choose to register or log in to our services using
            a social media account, we may have access to certain information
            about you.
          </em>
          <p className="mt-2">
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, and profile picture, as well as
            other information you choose to make public on such a social media
            platform.
          </p>
          <p className="mt-2">
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use, and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>
        </div>{" "}
        <h2 id="how-long-keep-information" className="mt-4 font-bold">
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h2>
        <div className="mt-2">
          <em>
            In Short: We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.
          </em>
          <p className="mt-2">
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p className="mt-2">
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.{" "}
          </p>
        </div>
        <h2 id="how-keep-safe" className="mt-4 font-bold">
          7. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h2>
        <div className="mt-2">
          <em>
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
          </em>
          <div className="mt-2">
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </div>
        </div>
        <h2 id="privacy-rights" className="mt-4 font-bold">
          8. WHAT ARE YOUR PRIVACY RIGHTS?
        </h2>
        <div className="mt-2">
          <em>
            In Short: In some regions, such as the European Economic Area (EEA)
            and United Kingdom (UK), you have rights that allow you greater
            access to and control over your personal information. You may
            review, change, or terminate your account at any time.
          </em>
          <div className="mt-2">
            <p>
              In some regions (like the EEA and UK), you have certain rights
              under applicable data protection laws. These may include the right
              (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. You can make such a request by contacting us by using
              the contact details provided in the section "HOW CAN YOU CONTACT
              US ABOUT THIS NOTICE?" below.
            </p>
            <p className="mt-2">
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>
            <p className="mt-2">
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </p>
            <p className="mt-2">
              If you are located in Switzerland, the contact details for the
              data protection authorities are available here:
              https://www.edoeb.admin.ch/edoeb/en/home.html.
            </p>
            <p className="mt-2">
              <strong>Withdrawing your consent:</strong> If we are relying on
              your consent to process your personal information, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent at any time by contacting us by using the contact details
              provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?" below. However, please note that this will not affect the
              lawfulness of the processing before its withdrawal, nor will it
              affect the processing of your personal information conducted in
              reliance on lawful processing grounds other than consent.
            </p>
            <strong className="my-2">Account Information</strong>
            <p className="mt-2">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul className="mt-2 list-disc pl-8">
              <li>
                Log in to your account settings and update your user account.
              </li>
              <li>Contact us using the contact information provided.</li>
            </ul>

            <p className="mt-2">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements. If you have questions or comments about your privacy
              rights, you may email us at{" "}
              <a
                className="underline text-blue-600"
                href="mailto:mrducmv88@gmail.com"
              >
                {" "}
                mrducmv88@gmail.com
              </a>
              .{" "}
            </p>
          </div>
        </div>
        <h2 id="do-not-track" className="mt-4 font-bold">
          9. CONTROLS FOR DO-NOT-TRACK FEATURES
        </h2>
        <p className="mt-2">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h2 id="california-residents" className="mt-4 font-bold">
          10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{" "}
        </h2>
        <div className="mt-2">
          <em>
            In Short: Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
          </em>
          <p className="mt-2">
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>
          <p className="mt-2">
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g., backups, etc.).
          </p>
        </div>
        <h2 id="update-notice" className="mt-4 font-bold">
          11. DO WE MAKE UPDATES TO THIS NOTICE?
        </h2>
        <div className="mt-2">
          <em>
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </em>
          <p className="mt-2">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.{" "}
          </p>
        </div>
        <h2 id="contact-notice" className="mt-4 font-bold">
          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </h2>
        <div className="mt-2">
          If you have questions or comments about this notice, you may email us
          at{" "}
          <a
            className="underline text-blue-600"
            href="mailto:mrducmv88@gmail.com"
          >
            mrducmv88@gmail.com
          </a>{" "}
          or by post to:
          <div>Duc Mai, pressarvägen 27</div>
          <div>Järfälla 17671</div>
          <div>Stockholm, Sweden</div>
        </div>
        <h2 id="review-data" className="mt-4 font-bold">
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h2>
        <p className="mt-2">
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please submit a request form by
          clicking here.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
