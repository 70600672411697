import { FunctionComponent } from "react";
import Footer from "./LandingPage/Footer";

interface LayoutProps {
    children: React.ReactNode;
}

const menu = [
    { label: "Getting started", url: "/" },
    { label: "App", url: "/app" },
    { label: "Privacy", url: "/privacy" },
  ];

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <div className="container m-auto min-h-screen text-center">
      <div className="min-h-screen bg-gray-100">
        <header className="top-0 bg-gray-100 z-10">
          <nav>
            <ul className="flex text-gray-600 px-4">
              {menu.map((item, index) => (
                <li
                  className={`${
                    item.url === window.location.pathname && "border border-indigo-500 rounded-full"
                  } my-4`}
                  key={item.url}
                >
                  <a className="px-4 inline-block font-medium" href={item.url}>
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </header>
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
