import { FunctionComponent } from "react";

interface GuideLinesProps {

}

const GuideLines: FunctionComponent<GuideLinesProps> = () => {
  return (<div className="relative -top-16">
    <h2 className="text-white text-4xl">Getting Started</h2>
    <div className="overflow-x-scroll snap-x scrollbar">
      <div className="mt-16 text-left grid lg:grid-cols-6 gap-4 mx-4 lg:mx-0 guides-scroll">
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium text-xl">1. Login with Google</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/login_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium text-xl">2. Set a name to your buy list</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/list_title_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium  text-xl">3. Add items to list</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/add_item_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium  text-xl">4. Share the list</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/share_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium  text-xl">5. Tick on items when they are bought</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/check_screen.png" alt="login" />
          </div>
        </div>
      
      </div>
    </div>
  </div>);
}

export default GuideLines;